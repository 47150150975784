
export default {
  methods: {
    osIcon(device) {
      const icons = {
        "Android": "mdi-android",
        "iOS": "mdi-apple",
        "Linux": "mdi-linux",
        "Mac OS": "mdi-apple",
        "Windows": "mdi-microsoft-windows",
      }

      return icons[device.os]
    },
    browserIcon(device) {
      const icons = {
        "Chrome": "mdi-google-chrome",
        "Firefox": "mdi-firefox",
        "Safari": "mdi-apple-safari",
        "Edge": "mdi-microsoft-edge",
        "Internet Explorer": "mdi-internet-explorer",
        "Opera": "mdi-opera",
      }

      return icons[device.browser]
    },

    icon(device) {
      const icons = {
        computer: "mdi-laptop",
        smartphone: "mdi-cellphone",
        tablet: "mdi-tablet",
      }

      return icons[device.type]
    },
  },
}
